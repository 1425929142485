import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import {
  getNotifications,
  getUnreadCount,
  getAllNotifications
} from '../../requests/notification';
import { RootChat } from 'types/Notification';

const initialState: RootChat = {
  notifications: [],
  unReadCount: 0,
  countLoaded: false,
  allNotifications: []
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    allNotifications(state, action) {
      state.allNotifications = action.payload;
    },
    notifications(state, action) {
      state.notifications = action.payload;
    },
    countLoaded(state, action) {
      state.countLoaded = action.payload;
    },
    unRead(state, action) {
      state.unReadCount = action.payload;
    }
  }
});
export default slice.reducer;

export const getUnRead = () => {
  getUnreadCount()
    .then((res) => {
      dispatch(slice.actions.countLoaded(true));
      dispatch(slice.actions.unRead(res));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getNotification = () => {
  getNotifications()
    .then((res) => {
      dispatch(slice.actions.notifications(res));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllNotificationsMessages = () => {
  getAllNotifications()
    .then((res) => {
      dispatch(slice.actions.allNotifications(res));
    })
    .catch((err) => {
      console.log(err);
    });
};

