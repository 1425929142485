import { createSlice } from '@reduxjs/toolkit';
import { getApplicants, getJobPosts } from 'requests/vacancy';
import { AppDispatch, RootState } from '../store';
import { enqueueSnackbar } from './notifications';
import { initialPaginated } from '../../constants/states';

const initialState = {
  isLoading: false,
  mostViewedPeriod: 'daily',
  ToDoPosts: initialPaginated,
  openJobPosts: initialPaginated,
  dailyApplicants: initialPaginated
};

const slice = createSlice({
  name: 'showcaseSlice',
  initialState,
  reducers: {
    showcaseLoading(state, action) {
      state.isLoading = action.payload;
    },
    jobPostsSuccess(state, action) {
      state.openJobPosts = action.payload;
    },
    ToDoPostsSuccess(state, action) {
      state.mostViewedPeriod = action.payload.periodicity;
      state.ToDoPosts = action.payload.ToDoPosts;
    },
    dailyApplicantsSuccess(state, action) {
      state.dailyApplicants = action.payload;
    }
  }
});

export const setLoading = (isLoading: boolean) => (dispatch: AppDispatch) => {
  dispatch(slice.actions.showcaseLoading(isLoading));
};

export const getShowcaseData =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { showcase } = getState();

    dispatch(setLoading(true));
    try {
      if (!showcase.openJobPosts.results) await dispatch(getJobPostData());
      if (!showcase?.dailyApplicants?.results) {
        await dispatch(getApplicantsData());
      }
    } catch {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getJobPostData = () => async (dispatch: AppDispatch) => {
  try {
    const openJobPosts = await getJobPosts({
      limit: 5,
      offset: 0,
      periodicity: 'yearly',
      short: true,
      ordering: 'created_at'
    });
    dispatch(slice.actions.jobPostsSuccess(openJobPosts));
  } catch {
    dispatch(
      enqueueSnackbar({
        messageType: 'error',
        options: { variant: 'error' }
      })
    );
  }
};

export const getApplicantsData = () => async (dispatch: AppDispatch) => {
  try {
    const dailyApplicants = await getApplicants({
      limit: 5,
      offset: 0,
      periodicity: 'yearly',
      short: true // daily
    });

    dispatch(slice.actions.dailyApplicantsSuccess(dailyApplicants));
  } catch {
    dispatch(
      enqueueSnackbar({
        messageType: 'error',
        options: { variant: 'error' }
      })
    );
  }
};

export default slice.reducer;
