import { setRequestError } from 'redux/slices/errors';
import { store } from 'redux/store';
import axios from '../utils/axios';

export type ID = string | number | null;

export const getDataByUrl = async (url: string, params?: any) => {
  const { dispatch } = store;
  try {
    const reqParams = params ? { params } : {};
    const response = await axios.get(url, reqParams);
    dispatch(setRequestError([{ response: { status: 200 } }]));
    return response.data;
  } catch (error: any) {
    dispatch(setRequestError(error));
    throw error[0]?.response?.data[0];
  }
};

export const postDataByUrl = async (url: string, data?: any, params?: any) => {
  const { dispatch } = store;
  try {
    const reqParams = params ? { params } : {};
    const response = await axios.post(url, data, reqParams);
    dispatch(setRequestError([{ response: { status: 200 } }]));
    return response.data;
  } catch (error: any) {
    dispatch(setRequestError(error));
    throw error[0]?.response?.data[0];
  }
};

export const updateDataByUrl = async (url: string, data: any, params?: any) => {
  const { dispatch } = store;
  try {
    const reqParams = params ? { params } : {};
    const response = await axios.patch(url, data, reqParams);
    dispatch(setRequestError([{ response: { status: 200 } }]));
    return response.data;
  } catch (error: any) {
    dispatch(setRequestError(error));
    throw error[0]?.response?.data[0];
  }
};

export const putDataByUrl = async (url: string, data: any, params?: any) => {
  const { dispatch } = store;
  try {
    const reqParams = params ? { params } : {};
    const response = await axios.put(url, data, reqParams);
    dispatch(setRequestError([{ response: { status: 200 } }]));
    return response.data;
  } catch (error: any) {
    dispatch(setRequestError(error));
    throw error[0]?.response?.data[0];
  }
};

export const deleteDataByUrl = async (url: string) => {
  const { dispatch } = store;
  try {
    const response = await axios.delete(url);
    dispatch(setRequestError([{ response: { status: 204 } }]));
    return response.status === 204;
  } catch (error: any) {
    dispatch(setRequestError(error));
    throw error[0]?.response?.data[0];
  }
};
