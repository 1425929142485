import { getDataByUrl, postDataByUrl, putDataByUrl } from 'requests';

export const orderCampaigns = (data: any) =>
  postDataByUrl(`/vonq/campaigns/`, data);

export const getCampaigns = (params?: any) =>
  getDataByUrl(`/vonq/campaigns/`, params);

export const getCampaignsDetails = (id: number | string) =>
  getDataByUrl(`/vonq/campaigns/${id}/details/`);

export const getCampaignsStatus = (id: number | string) =>
  getDataByUrl(`/vonq/campaigns/${id}/status/`);

export const getOneCampaign = (id: number | string) =>
  getDataByUrl(`/vonq/campaigns/${id}/`);

export const cancelCampaign = (id: number | string) =>
  putDataByUrl(`/vonq/campaigns/${id}/cancel/`, {});
