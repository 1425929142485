import { postDataByUrl } from 'requests';
import axios from '../utils/axios';

const getDataByUrl = async (url: string, params?: any) => {
  try {
    const response = await axios.get(url, params ? { params } : {});
    return response.data;
  } catch {
    return null;
  }
};

export const getLanguages = (params: any) =>
  getDataByUrl(`/fixtures/languages/?q=${params}&lang=en`);
export const getLocations = (params: any) =>
  getDataByUrl('https://nora.recrout.com/api/cities/', params);
export const getSkills = (params: any) =>
  getDataByUrl(`/fixtures/skills/?q=${params}&lang=en`);
export const getIndustries = (params: any) =>
  getDataByUrl(`/fixtures/industries/?q=${params}&lang=en`);
export const getDegrees = (params: any) =>
  getDataByUrl(`/fixtures/degrees/?q=${params}&lang=en`);
export const getSchools = (params: any) =>
  getDataByUrl(`/fixtures/schools/?q=${params}&lang=en`);
export const getAllJobPosts = () => getDataByUrl('/vacancy/');
export const getAnalyticsJobsPeriod = (periodicity: string, jobId?: number) =>
  getDataByUrl(
    `/analytics/job_applications/?periodicity=${periodicity}&jobPost=${
      jobId || null
    }`
  );
export const getAnalyticsJobsData = (periodicity: string) =>
  getDataByUrl(`/analytics/job_applications_data/?periodicity=${periodicity}`);
export const getAnalyticsJobPostDurations = () =>
  getDataByUrl(`/analytics/job_post_durations/`);
export const getAnalyticsJobCosts = () =>
  getDataByUrl(`/analytics/job_post_cost/`);
export const getApplicationStagesPie = (jobId?: number) =>
  getDataByUrl(
    `/analytics/job_application_stages_pie/?jobPost=${jobId || null}`
  );
export const getJobApplicationStages = (periodicity: string, jobId?: number) =>
  getDataByUrl(
    `/analytics/job_application_stages/?periodicity=${periodicity}&jobPost=${
      jobId || null
    }`
  );
export const getApplicationStagesPieForExcel = () =>
  getDataByUrl(`/analytics/job_application_stages_data/`);
export const getJobsAnalytics = (periodicity: string) =>
  getDataByUrl(`/analytics/job_posts/?periodicity=${periodicity}`);
export const getCultureProfiles = (query?: string) =>
  getDataByUrl(
    `/match/profiles/search/${query ? `?category=culture&q=${query}` : ''}`
  );
export const getCompetency = () =>
  getDataByUrl('/assessment/competencies_by_group/');
export const getRemoteJobs = (query?: string) =>
  getDataByUrl(`/kombo/jobs/?q=${query || ''}`);
export const getCompetencies = () => getDataByUrl('/assessment/competencies/');
export const getAssessmentResults = (userId: number | string) =>
  getDataByUrl(`/assessment/assessments/result/?candidate_id=${userId}`);

export const getCustomers = (search: string) =>
  getDataByUrl(`/customers/?q=${search}&short=true`);

export const getMagicLink = async (id: any) =>
  postDataByUrl(`/magic_links/token/`, id);
