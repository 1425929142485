import { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { withTranslation } from 'react-i18next';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import MainLayout from 'layouts/MainLayout';
import { ConnectedRouter } from 'connected-react-router';
import { history, store } from './redux/store';
import routes, { renderRoutes } from './routes';
import ThemeConfig from './theme';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import JwtProvider from './components/authentication/JwtProvider';
import Notifier from './components/Notifier';

import './App.scss';

function App({ tReady }: { tReady?: boolean }) {
  const [home, setHome] = useState(false);
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <ThemeConfig home={home}>
          <RtlLayout>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <NotistackProvider>
                <Notifier />
                <ConnectedRouter history={history}>
                  <MainLayout location={setHome}>
                    {tReady ? (
                      <JwtProvider>
                        <ScrollToTop />
                        <GoogleAnalytics />
                        {renderRoutes(routes)}
                      </JwtProvider>
                    ) : (
                      <LoadingScreen />
                    )}
                  </MainLayout>
                </ConnectedRouter>
              </NotistackProvider>
            </LocalizationProvider>
          </RtlLayout>
        </ThemeConfig>
      </ReduxProvider>
    </HelmetProvider>
  );
}

export default withTranslation()(App);
