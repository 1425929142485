import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import HomeLayout from '../layouts/home';
import { PATH_HOME } from './paths';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: PATH_HOME.home,
      component: lazy(() => import('views/home/LandingPage'))
    },
    {
      exact: true,
      path: PATH_HOME.jobs,
      component: lazy(() => import('views/home/JobsPage'))
    },
    {
      exact: true,
      path: PATH_HOME.job,
      component: lazy(() => import('views/home/JobPage'))
    },
    {
      exact: true,
      path: PATH_HOME.demo,
      component: lazy(() => import('views/Demo'))
    },
    {
      exact: true,
      path: PATH_HOME.assessment,
      component: lazy(() => import('views/StartAssessment'))
    },
    {
      path: PATH_HOME.assessmentResult,
      component: lazy(() => import('../views/dashboard/AssessmentResultsWait'))
    },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404/" />
    }
  ]
};

export default HomeRoutes;
