import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState, store } from "redux/store";
import { deleteApiKeyData, getApiKeys, postApiKeys } from "requests/apiKeys";
import { enqueueSnackbar } from "./notifications";
import { prepareUpdatedData } from "helpers/listValues";

const initialState = {
  isLoading: false,
  results: [] as any,
  reload: false,
  talentFilters: {
    page: 1,
    offset: 0,
    limit: 5,
  },
  post: null as any,
  count: 0
};

const slice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    setKeysSuccess(state, action) {
      state.results = action.payload.result.results
      state.count = action.payload.result.count
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    postSuccess(state, action) {
      state.reload = !state.reload
      state.post = action.payload.result
    },
    setKeysDelete(state, action) {
      state.reload = !state.reload
    },
    changeFilter(state, action) {
      state.talentFilters = {
        ...state.talentFilters,
        ...action.payload
      };
    },
  },
});
export default slice.reducer;

export const getKeysIntegration = (params?: any) =>
  async (dispatch: AppDispatch) => {
    getApiKeys(params)
      .then((res) => {
        dispatch(
          slice.actions?.setKeysSuccess({
            result: res
          }),
        )
      }
      )
      .catch(() => {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      })

  }

export const postApiKeysIntegration = (data: any) => async (
  dispatch: AppDispatch,
) => {
  postApiKeys(data)
    .then((res) => {
      dispatch(
        slice.actions?.postSuccess({
          result: res
        })
      );
      dispatch(
        enqueueSnackbar({
          messageType: 'created',
          options: {
            variant: 'success'
          }
        })
      );
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    }
    )

}

export const apiKeydelete = (id: string) => async (
  dispatch: AppDispatch,
) =>
  deleteApiKeyData(id)
    .then((res) => {
      dispatch(
        slice.actions.setKeysDelete({
          result: res
        })
      );
      dispatch(
        enqueueSnackbar({
          messageType: 'deleted',
          options: {
            variant: 'success'
          }
        })
      );
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const changeFilters =
  (fieldName: string, value: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(
        slice.actions.changeFilter({
          [fieldName]: value
        })
      );
    } catch {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    }
  };
