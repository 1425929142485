import LoadingScreen from 'components/LoadingScreen';
import useAuth from 'hooks/useAuth';
import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialDashboardData } from 'redux/slices/generalRecruiter';
import { RootState } from 'redux/store';

type DashboardRequestCheckerProps = {
  fromCustomer: boolean;
  children: ReactNode;
};

const DashboardRequestChecker = ({
  fromCustomer,
  children
}: DashboardRequestCheckerProps) => {
  // NOTE: If you need to get some data before rendering dashboard, make it here.
  const dispatch = useDispatch();
  const { loading }: any = useSelector((state: RootState) => state);
  const { user } = useAuth();
  const { role } = user;

  useEffect(() => {
    if (!fromCustomer) {
      dispatch(getInitialDashboardData(role));
    }
  }, [dispatch, fromCustomer, role]);

  if (loading.dashboard) return <LoadingScreen />;
  return <>{children}</>;
};

export default DashboardRequestChecker;
