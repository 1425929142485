import {
  deleteDataByUrl,
  getDataByUrl,
  ID,
  postDataByUrl,
  updateDataByUrl
} from 'requests';

export const getCandidateOverview = async (candidate_id: ID) =>
  getDataByUrl(`/candidate/overview/${candidate_id}/`);

export const getCandidateData = async (candidate_id: ID, route: string) =>
  getDataByUrl(`/candidate/${candidate_id}/${route}/`);

export const getCandidateAssessment = async (id: ID) =>
  getDataByUrl(`/assessment/assessments/result/?candidate_id=${id}`);

export const postCandidateData = async (
  candidate_id: ID,
  formData: any,
  route: string
) => postDataByUrl(`/candidate/${candidate_id}/${route}/`, formData);

export const postCustomerData = async (
  customer_id: ID,
  formData: any,
  route: string
) => postDataByUrl(`/customers/${customer_id}/${route}/`, formData);

export const updateCandidateData = async (
  candidate_id: ID,
  id: ID,
  formData: any,
  route: string
) => updateDataByUrl(`/candidate/${candidate_id}/${route}/${id}/`, formData);

export const submitCandidateData = async (
  candidate_id: ID,
  formData: any,
  route: string,
  id?: ID
) =>
  (id ? updateDataByUrl : postDataByUrl)(
    `/candidate/${candidate_id}/${route}${id ? `/${id}` : ''}/`,
    formData
  );

export const updateCustomerNotes = async (
  customer_id: ID,
  id: ID,
  formData: any,
  route: string
) => updateDataByUrl(`/customers/${customer_id}/${route}/${id}/`, formData);

export const updateCandidateMainInfo = async (
  candidate_id: ID,
  formData: any
) => updateDataByUrl(`/candidate/${candidate_id}/`, formData);

export const deleteCandidateData = async (
  candidate_id: string | number,
  id: string | number,
  route: string
) => deleteDataByUrl(`/candidate/${candidate_id}/${route}/${id}/`);

export const deleteCustomerData = async (
  customer_id: string | number,
  id: string | number,
  route: string
) => deleteDataByUrl(`/customers/${customer_id}/${route}/${id}/`);

export const updateApplicationData = async (id: ID, formData: any) =>
  updateDataByUrl(`/vacancy/applications/${id}/manage/`, formData);

export const cancelCandidateApplication = async (id: ID) =>
  updateDataByUrl(`/vacancy/applications/${id}/cancel/`, {});

export const getAssessmentTestLink = async (user: ID) =>
  postDataByUrl(`/assessment/assessments/`, { user });

export const getAllCandidate = async (
  user: ID,
  q: string,
  currentPage: number,
  limit: number
) =>
  getDataByUrl(
    `/candidate/?limit=${limit}&offset=${limit * currentPage - limit}&q=${q}`,
    { user }
  );

export const createCandidate = async (data: any) =>
  postDataByUrl('/candidate/', data);
export const importCandidate = async (formData: any) =>
  postDataByUrl('/candidate/import/', formData);

export const getCandidateRemind = async (candidate_id: ID) =>
  getDataByUrl(`/candidate/${candidate_id}/remind/`);

export const getCandidatePublicProfileData = async (
  candidate_id: ID,
  token: string
) => getDataByUrl(`/candidate/${candidate_id}/${token}/`);