import { initialPaginated } from 'constants/states';
import { enqueueSnackbar } from './notifications';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'redux/store';
import { getAssessmentVacancy } from 'requests/assessment';

const initialState = {
  assessmentProfile: initialPaginated
};

const slice = createSlice({
  name: 'assessmentResultSlice',
  initialState,
  reducers: {
    getAssessmentProfileSuccess(state, action) {
      state.assessmentProfile = action.payload;
    }
  }
});

export default slice.reducer;

export const getAssessmentVacancyResult =
  (id: string | number, token: string) => async (dispatch: AppDispatch) => {
    getAssessmentVacancy(id, token)
      .then((res) =>
        dispatch(
          slice.actions.getAssessmentProfileSuccess({
            assessmentProfile: res
          })
        )
      )
      .catch(() => {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      });
  };
