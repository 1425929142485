import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../store';
import { IInitialState } from './types';
import { enqueueSnackbar } from './notifications';
import { cancelCampaign, getCampaigns, getCampaignsDetails } from 'utils/vonq';

const initialState: IInitialState = {
  isLoading: false,
  count: 0,
  campaigns: [],
  oneCampaign: {}
};

const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCampaigns(state, action) {
      state.campaigns = [...action.payload.campaigns];
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setOneCampaign(state, action) {
      state.oneCampaign = { ...action.payload };
    }
  }
});

export default slice.reducer;

export const getCampaignsThunk =
  (
    user_id: number | null,
    offset?: number,
    limit?: number,
    vacancy_id?: string | number,
    period?: string,
    status?: number,
    vacancy_title?: string,
    id?: string | number
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    if (id) await cancelCampaign(id);
    try {
      const res = await getCampaigns({
        user_id,
        offset,
        limit,
        vacancy_id,
        period,
        status,
        vacancy_title
      });
      dispatch(slice.actions.setCampaigns({ campaigns: res.results }));
      dispatch(slice.actions.setCount(res.count));
    } catch {
      enqueueSnackbar({
        message: 'Something went wrong',
        options: { variant: 'error' }
      });
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };

export const getOneCampaignThunk =
  (id: number | string, isDelete = false) =>
  async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      if (isDelete) await cancelCampaign(id);
      const resDetails = await getCampaignsDetails(id);
      dispatch(slice.actions.setOneCampaign(resDetails.data));
    } catch {
      enqueueSnackbar({
        message: 'Something went wrong',
        options: { variant: 'error' }
      });
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
