import { getDataByUrl } from './index';

export const getAllNotifications = async () =>
  getDataByUrl(`/notifications/all/`);
export const getNotifications = async () =>
  getDataByUrl(`/notifications/unread/`);
export const getUnreadCount = async () =>
  getDataByUrl(`/notifications/api/unread_count/`);
export const getAllReadNotifications = async () =>
  getDataByUrl(`/notifications/mark-all-as-read/`);
export const getAsReadNotifications = async (id: any) =>
  getDataByUrl(`/notifications/mark-as-read/${id}/`);
