import { getDataByUrl, postDataByUrl } from 'requests';

export const startAssessment = async (data: any, token: string) =>
  postDataByUrl(`/plugin/start/${token}/`, data);

export const getAssessmentResults = async (token: string) =>
  getDataByUrl(`/plugin/check_assessment_result/${token}/`);

export const getAssessmentVacancy = async (
  id: string | number,
  token: string
) => getDataByUrl(`/vacancy/applications/${id}/${token}/`);
