import { createSlice } from '@reduxjs/toolkit';
import {
  createCandidate,
  getAllCandidate,
  getCandidateAssessment,
  getCandidateData,
  getCandidateOverview,
  getCandidatePublicProfileData,
  importCandidate,
  updateCandidateMainInfo
} from 'requests/candidate';
import { ID } from 'requests';
import { AppDispatch } from 'redux/store';
import {
  initialAllCandidates,
  initialAssessmentRes,
  initialCandidate
} from 'constants/states';
import { enqueueSnackbar } from './notifications';
import { LIMIT } from '../../constants/constants';

const initialState = {
  allCandidates: initialAllCandidates,
  candidateLoading: false,
  candidate: initialCandidate,
  talentFilters: {
    currentPage: 1,
    currentRole: 'all_candidates'
  },
  assessmentLoading: false,
  assessmentResult: initialAssessmentRes,
  publicCandidate: {}
};

const slice = createSlice({
  name: 'candidateSlice',
  initialState,
  reducers: {
    setAllCandidates(state, action) {
      state.allCandidates = action.payload;
    },
    candidateProfileLoading(state, action) {
      state.candidateLoading = action.payload;
    },
    assessmentProfileLoading(state, action) {
      state.assessmentLoading = action.payload;
    },
    resetProfileSuccess(state) {
      state.candidate = initialCandidate;
      state.assessmentResult = initialAssessmentRes;
    },
    candidateDataSuccess(state, action) {
      state.candidate = {
        ...state.candidate,
        ...action.payload
      };
    },
    changeFilter(state, action) {
      state.talentFilters = {
        ...state.talentFilters,
        ...action.payload
      };
    },
    candidateAllSuccess(state, action) {
      state.candidate = {
        ...state.candidate,
        ...action.payload
      };
    },
    candidateAssessmentResultSuccess(state, action) {
      state.assessmentResult = action.payload.assessmentResult;
    },
    candidatePublicDataSuccess(state, action) {
      state.publicCandidate = {
        ...state.publicCandidate,
        ...action.payload
      };
    }
  }
});

export default slice.reducer;

export const setCandidateLoading =
  (isLoading: boolean) => (dispatch: AppDispatch) =>
    dispatch(slice.actions.candidateProfileLoading(isLoading));

export const setAssessmentLoading =
  (isLoading: boolean) => (dispatch: AppDispatch) =>
    dispatch(slice.actions.assessmentProfileLoading(isLoading));

export const resetCandidate = () => (dispatch: AppDispatch) =>
  dispatch(slice.actions.resetProfileSuccess());

export const getAllCandidateData =
  (id: ID, modules: any) => async (dispatch: AppDispatch) => {
    dispatch(setCandidateLoading(true));
    try {
      await dispatch(getCandidateInfo(id));
      modules?.notes && (await dispatch(getCandidateNotes(id)));
      // modules?.work_experience &&
      //   (await dispatch(getCandidateWorkExperience(id)));
      // modules?.education && (await dispatch(getCandidateEducations(id)));
      // await dispatch(getCandidateFiles(id));
      // modules?.language && (await dispatch(getCandidateLanguages(id)));
      // modules?.extra && (await dispatch(getCandidateActivities(id)));
      // modules?.certification && (await dispatch(getCandidateCertifications(id)));
      // modules?.it_skill && (await dispatch(getCandidateITSkills(id)));
      // modules?.social_media && (await dispatch(getCandidateSocialMedia(id)));
      // modules?.social_media_expertise &&
      //   (await dispatch(getCandidateSocialMediaExpertises(id)));
    } catch {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    } finally {
      dispatch(setCandidateLoading(false));
    }
  };

export const getCandidateInfo = (id: ID) => async (dispatch: AppDispatch) =>
  getCandidateOverview(id)
    .then((res) => {
      dispatch(slice.actions.candidateDataSuccess({ mainInfo: res }));
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const removeCandidateInfo = () => async (dispatch: AppDispatch) =>
  dispatch(
    slice.actions.candidateDataSuccess({ mainInfo: initialCandidate.mainInfo })
  );

export const getCandidateWorkExperience =
  (id: ID) => async (dispatch: AppDispatch) =>
    getCandidateData(id, 'work-experiences')
      .then((res) =>
        dispatch(
          slice.actions.candidateDataSuccess({
            work_experience: res
          })
        )
      )
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );

export const getCandidateEducations =
  (id: ID) => async (dispatch: AppDispatch) =>
    getCandidateData(id, 'educations')
      .then((res) =>
        dispatch(
          slice.actions.candidateDataSuccess({
            educations: res
          })
        )
      )
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );

export const getCandidateFiles = (id: ID) => async (dispatch: AppDispatch) =>
  getCandidateData(id, 'files')
    .then((res) =>
      dispatch(
        slice.actions.candidateDataSuccess({
          files: res
        })
      )
    )
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const getCandidateNotes = (id: ID) => async (dispatch: AppDispatch) =>
  getCandidateData(id, 'notes')
    .then((res) => {
      dispatch(
        slice.actions.candidateDataSuccess({
          notes: res
        })
      );
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const getCandidateLanguages =
  (id: ID) => async (dispatch: AppDispatch) =>
    getCandidateData(id, 'languages')
      .then((res) =>
        dispatch(
          slice.actions.candidateDataSuccess({
            languages: res
          })
        )
      )
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );

export const getCandidateActivities =
  (id: ID) => async (dispatch: AppDispatch) =>
    getCandidateData(id, 'activities')
      .then((res) =>
        dispatch(
          slice.actions.candidateDataSuccess({
            activities: res
          })
        )
      )
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );

export const getCandidateCertifications =
  (id: ID) => async (dispatch: AppDispatch) =>
    getCandidateData(id, 'certifications')
      .then((res) =>
        dispatch(
          slice.actions.candidateDataSuccess({
            certifications: res
          })
        )
      )
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );

export const getCandidateITSkills = (id: ID) => async (dispatch: AppDispatch) =>
  getCandidateData(id, 'it-skills')
    .then((res) =>
      dispatch(
        slice.actions.candidateDataSuccess({
          it_skill: res
        })
      )
    )
    .catch(() =>
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      )
    );

export const getCandidateSocialMedia =
  (id: ID) => async (dispatch: AppDispatch) =>
    getCandidateData(id, 'social-media')
      .then((res) =>
        dispatch(
          slice.actions.candidateDataSuccess({
            social_media: res
          })
        )
      )
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );

export const getCandidateSocialMediaExpertises =
  (id: ID) => async (dispatch: AppDispatch) =>
    getCandidateData(id, 'social-media-expertises')
      .then((res) =>
        dispatch(
          slice.actions.candidateDataSuccess({
            social_media_expertise: res
          })
        )
      )
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );

export const patchCandidateMainInfoData =
  (id: ID, formData: any) => async (dispatch: AppDispatch) =>
    updateCandidateMainInfo(id, formData)
      .then((res) =>
        dispatch(
          slice.actions.candidateDataSuccess({
            mainInfo: res
          })
        )
      )
      .then(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'updated',
            options: { variant: 'success' }
          })
        )
      );

export const getCandidateAssessmentResults =
  (id: ID) => async (dispatch: AppDispatch) => {
    dispatch(setAssessmentLoading(true));
    getCandidateAssessment(id)
      .then((res) =>
        dispatch(
          slice.actions?.candidateAssessmentResultSuccess({
            assessmentResult: Object.keys(res).length
              ? res
              : initialAssessmentRes
          })
        )
      )
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      )
      .finally(() => dispatch(setAssessmentLoading(false)));
  };

export const getAllUserAndAdmin =
  (id: ID, q: string, currentPage: number) => async (dispatch: AppDispatch) => {
    dispatch(setCandidateLoading(true));
    getAllCandidate(id, q, currentPage, LIMIT)
      .then((res) => {
        dispatch(slice.actions.setAllCandidates(res));
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      })
      .finally(() => {
        dispatch(setCandidateLoading(false));
      });
  };

export const changeFilters =
  (fieldName: string, value: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(
        slice.actions.changeFilter({
          [fieldName]: value
        })
      );
    } catch {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    }
  };

export const createNewCandidate =
  (data: any, message: string) => async (dispatch: AppDispatch) => {
    try {
      createCandidate(data).then(() =>
        dispatch(
          enqueueSnackbar({
            message,
            messageType: 'updated',
            options: { variant: 'success' }
          })
        )
      );
    } catch {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    }
  };

export const importCandidateFile =
  (formData: any) => async (dispatch: AppDispatch) => {
    importCandidate(formData)
      .then((res) =>
        dispatch(
          enqueueSnackbar({
            message: res.status,
            options: { variant: 'success' }
          })
        )
      )
      .catch((error) =>
        dispatch(
          enqueueSnackbar({
            message: error,
            options: { variant: 'error' }
          })
        )
      );
  };

export const getCandidatePublicInfo =
  (candidate_id: ID, token: string) => async (dispatch: AppDispatch) => {
    getCandidatePublicProfileData(candidate_id, token)
      .then((res) => {
        dispatch(slice.actions.candidatePublicDataSuccess(res));
      })
      .catch(() =>
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        )
      );
  };
