import { useMemo, ReactNode } from 'react';
// material
import { CssBaseline } from '@material-ui/core';
import {
  ThemeOptions,
  ThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
// hooks
import useSettings from '../hooks/useSettings';
//
import shape from './shape';
import getPalette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import { getShadows, getCustomShadows } from './shadows';
import useColors from '../hooks/useColors';

// ----------------------------------------------------------------------

type ThemeConfigProps = {
  home: boolean;
  children: ReactNode;
};

export default function ThemeConfig({ home, children }: ThemeConfigProps) {
  const { themeMode, themeDirection } = useSettings();
  const { primary_color, secondary_color } = useColors();

  const isLight = themeMode === 'light';

  const themeOptions: ThemeOptions = useMemo(() => {
    const palette = getPalette(home ? primary_color : '#562C82', secondary_color || '#562C82');
    const shadows = getShadows();
    const customShadows = getCustomShadows(palette);
    return {
      palette: isLight
        ? { ...palette.light, mode: 'light' }
        : { ...palette.dark, mode: 'dark' },
      typography,
      shape,
      breakpoints,
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark
    };
  }, [isLight, themeDirection, secondary_color, primary_color, home]);

  const theme = createMuiTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
