import { ReactNode } from 'react';
import { Redirect, useHistory } from 'react-router';
// material
import { Box } from '@material-ui/core';
//
import { useSelector } from 'react-redux';
import HomeNavbar from './HomeNavbar';
import { RootState } from '../../redux/store';
import { PATH_AUTH } from '../../routes/paths';

// ----------------------------------------------------------------------

type HomeLayoutProps = {
  children: ReactNode;
};

export default function HomeLayout({ children }: HomeLayoutProps) {
  const { location }: any = useHistory();
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );

  if (location.pathname === '/') return <Redirect to={PATH_AUTH.login} />;

  return (
    <Box sx={{ height: '100%' }}>
      <HomeNavbar logo={company.logo || null} />
      <Box sx={{ height: '100%' }}>{children}</Box>
    </Box>
  );
}
